import React from 'react'
import { Card,  Col, Container, Row } from 'react-bootstrap'
import img from '../assest/img/1.png';
import img1 from '../assest/img/1-1.png';
import img2 from '../assest/img/2.png';
import img3 from '../assest/img/3.png';
import img4 from '../assest/img/4.png';
import img5 from '../assest/img/4-1.png';
import img6 from '../assest/img/4-2.png';
import img7 from '../assest/img/5.png';
import { Button } from 'react-bootstrap';


export default function Features() {
  return (
    <Container id='features'>
        <Row>
            <Col className='d-flex justify-content-center'>

            <div style={{ marginTop: "70px", textAlign: "center" }}>
            <p style={{ color: "#ADD8E6", fontSize: '24px' }}>Features</p>

  <h3 style={{ color: "white", fontSize: '48px' }}>Our comprehensive DNS service</h3>
  
  <h3 style={{ color: "white", fontSize: '48px' }}>It includes the following:</h3>
</div>
                    
               
            </Col>
        </Row>
        <Row>
            <Col className='d-flex justify-content-center'>
                
            <div style={{ textAlign: "center" }}>
  <p className='subtitle'>We are passionate about DNS security and have a proven track record in delivering top-notch services to businesses of all sizes.</p>
</div>
            </Col>
        </Row>
        <Row className='mt-5'>
    <Col>
    <Card className="h-100 transparent-card position-relative">
  <Card.Body className="d-flex flex-column align-items-center justify-content-center">
    <div className='mt-5 mb-3 text-center'>
      <img src={img}  className="img-fluid position-absolute top-0 start-50 translate-middle-x" style={{ zIndex: 1 }}  /><br/><br/>
      <h4 className="position-relative" style={{ zIndex: 2 ,color:"white"}}>DNS Server</h4>
    </div>
    <p className='subtitle text-center'>Has its own unique DNS server, which has been developed entirely from scratch and designed to take the fastest possible action in terms of security mechanisms.</p>
  </Card.Body>
</Card>
    </Col>
    <Col>
    <Card className="h-100 transparent-card position-relative">
  <Card.Body className="d-flex flex-column align-items-center justify-content-center">
    <div className='mt-5 mb-3 text-center'>
      <img src={img1}  className="img-fluid position-absolute top-0 start-50 translate-middle-x" style={{ zIndex: 1 }}  /><br/><br/>
      <h4 className="position-relative" style={{ zIndex: 2 ,color:"white"}}>DNS Routing</h4>
    </div>
    <p className='subtitle text-center'>You can configure DNS routing using rule-based approaches, similar to writing rules, and utilize wildcards in your routing configurations.</p>
  </Card.Body>
</Card>
    </Col>
    <Col>
    <Card className="h-100 transparent-card position-relative">
  <Card.Body className="d-flex flex-column align-items-center justify-content-center">
    <div className='mt-5 mb-3 text-center'>
      <img src={img2}  className="img-fluid position-absolute top-0 start-50 translate-middle-x" style={{ zIndex: 1 }}  /><br/><br/>
      <h4 className="position-relative" style={{ zIndex: 2 ,color:"white"}}>DNS Firewall</h4>
    </div>
    <p className='subtitle text-center'>You can block access to categorized domain names using the rules you create with NameDefence, or you can redirect them to desired pages.</p>
  </Card.Body>
</Card>
    </Col>
    <Col>
    <Card className="h-100 transparent-card position-relative">
  <Card.Body className="d-flex flex-column align-items-center justify-content-center">
    <div className='mt-5 mb-3 text-center'>
      <img src={img3}  className="img-fluid position-absolute top-0 start-50 translate-middle-x" style={{ zIndex: 1 }}  /><br/><br/>
      <h4 className="position-relative" style={{ zIndex: 2 ,color:"white"}}>Central Management</h4>
    </div>
    <p className='subtitle text-center'>Quickly be deployed across your entire DNS infrastructure through centralized policies, eliminating the need for individual server configurations.</p>
  </Card.Body>
</Card>
    </Col>
    </Row>
    <Row>
    <Col>
    <Card className="h-100 transparent-card position-relative">
  <Card.Body className="d-flex flex-column align-items-center justify-content-center">
    <div className='mt-5 mb-3 text-center'>
      <img src={img4}  className="img-fluid position-absolute top-0 start-50 translate-middle-x" style={{ zIndex: 1 }}  /><br/><br/>
      <h4 className="position-relative" style={{ zIndex: 2 ,color:"white"}}>AI-Supported Categorization</h4>
    </div>
    <p className='subtitle text-center'>With AI and Guardpot agents, an undetectable DNS categorization is provided, which includes bot-protected sites and even evasive ones.</p>
  </Card.Body>
</Card>
    </Col>
   
    <Col>
    <Card className="h-100 transparent-card position-relative">
  <Card.Body className="d-flex flex-column align-items-center justify-content-center">
    <div className='mt-5 mb-3 text-center'>
      <img src={img6}  className="img-fluid position-absolute top-0 start-50 translate-middle-x" style={{ zIndex: 1 }}  /><br/><br/>
      <h4 className="position-relative" style={{ zIndex: 2 ,color:"white"}}>Dynamic Cache Policy</h4>
    </div>
    <p className='subtitle text-center'>You can manipulate DNS TTL cache parameters as you wish, thus preventing unnecessary traffic from occurring.</p>
  </Card.Body>
</Card>
    </Col>
    <Col>
    <Card className="h-100 transparent-card position-relative">
  <Card.Body className="d-flex flex-column align-items-center justify-content-center">
    <div className='mt-5 mb-3 text-center'>
      <img src={img7}  className="img-fluid position-absolute top-0 start-50 translate-middle-x" style={{ zIndex: 1 }}  /><br/><br/>
      <h4 className="position-relative" style={{ zIndex: 2 ,color:"white"}}>Monitoring Screens</h4>
    </div>
    <p className='subtitle text-center'>
    You can track user DNS requests with timestamps and detect their habits in categorical and URL-based live screens.</p>
  </Card.Body>
</Card>
    </Col>
    <Col>
    <Card className="h-100 transparent-card position-relative">
  <Card.Body className="d-flex flex-column align-items-center justify-content-center">
    <div className='mt-5 mb-3 text-center'>
      <img src={img5}  className="img-fluid position-absolute top-0 start-50 translate-middle-x" style={{ zIndex: 1 }}  /><br/><br/>
      <h4 className="position-relative" style={{ zIndex: 2 ,color:"white"}}>Wildcard Policies</h4>
    </div>
    <p className='subtitle text-center'>The use of wildcards provides an effective solution by handling letter or number additions in domain names with a single rule.</p>
  </Card.Body>
</Card>
    </Col>
</Row>
<Row className="justify-content-center">
<Button href='/auth' variant='outline-light' className="mx-4" style={{ width:"250px", height:"40px", textAlign:"center", margin:"center" }}> Request a Demo</Button>

</Row>

    </Container>
  )
}
