import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import logo from '../assest/img/NameDefence.png';
import Facebook from '../assest/img/Facebook.png';
import Instagram from '../assest/img/Instagram.png';
import X from '../assest/img/X.png';
import LinkedIn from '../assest/img/LinkedIn.png';

export default function Footer() {
    return (
        <div id="aboutUs">
            <Container>
                <Row className='py-3 py-md-5'>
                    <Col xs={12} md={4} className='d-flex align-items-center'>
                        <img src={logo} alt="Logo" />
                    </Col>
                    <Col xs={12} md={8} className='d-flex align-items-center justify-content-center '>
                        <div className='d-flex flex-column flex-md-row justify-content-center mx-5' >
                          
                        </div>
                        <div className='d-flex justify-content-center justify-content-md-end mx-5'>
                            <img href="https://www.instagram.com/prientegroup/" src={Instagram} alt="Instagram" className='mx-1' />
                            <img  href="https://twitter.com/prientegroup" src={X} alt="X" className='mx-1' />
                            <img  href="https://www.linkedin.com/company/priente/" src={LinkedIn} alt="LinkedIn" className='mx-1' />
                        </div>
                    </Col>
                </Row>
                <Row className='mb-2 d-flex justify-content-center px-3'>
                    <Col xs={12} md={8} lg={6} className='text-center'>
                        <p className='mb-0' style={{ color: "gray", fontSize: '12px' }}>© 2024 Priente Software. All Rights Reserved.</p>
                        <p className='mb-0' style={{ color: "gray", fontSize: '12px' }}></p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
