import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Dropdown } from 'react-bootstrap';
import styles from './Header.module.css'; // CSS dosyasını import et
import logo from '../assest/img/NameDefence.png'
import img from '../assest/img/turkeyFlag.svg'

export default function Header() {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${styles.navbar} ${isSticky ? styles.sticky : ''}`}>
      <Container className='py-4'>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
          <img src={logo} alt="Logo" />

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={`${styles.right}`}>
            

              <Button href='/auth/' variant='outline-light' className="mx-4"  >Try Now</Button>
              <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        <img src={img}/> EN
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1"><img src={img}/> EN</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </Container>
     
    </div>
  );
}
