import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from "react-slick";
import worldNorth from '../assest/img/Steps=1.png';
import worldSouth from '../assest/img/Steps=2.png';
import worldEast from '../assest/img/Steps=3.png';
import worldWest from '../assest/img/Steps=4.png';
import img from '../assest/img/rowImg.svg';
import img2 from '../assest/img/rowImg2.svg';
import img3 from '../assest/img/rowImg3.svg';
import img4 from '../assest/img/rowImg4.svg';
import img5 from '../assest/img/rowImg5.svg';

import { Button } from 'react-bootstrap';

export default function Home() {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
      setInputValue(e.target.value);
  };

  const handleAssignButtonClick = () => {
      // Atama butonuna tıklama işlemleri burada yapılabilir
      console.log("Input value:", inputValue);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const images = [worldNorth, worldSouth, worldEast, worldWest];

  const [currentImage, setCurrentImage] = useState(0);
  const [isRotating, setIsRotating] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRotating) {
        setCurrentImage((currentImage + 1) % images.length);
      }
    }, 200); // Her 5 saniyede bir resim değiştirir
  
    return () => clearInterval(interval);
  }, [isRotating, currentImage, images.length]);
  

  const handleRotation = () => {
    setIsRotating(!isRotating);
  };

  return (
    <Container id='home'>
      <Row className='mt-5'>
        <Col md={6} className="d-flex justify-content flex-column" style={{ marginTop: '100px' }}>
          <h1 style={{ fontSize: '72px' }}>
            <strong>  Dynamic Name   </strong><br />
            <strong>Server and Firewall</strong>
          </h1>
          <p style={{ fontSize: "1rem", color: "gray" }}>Expert Solutions for Current Cyber Threats</p>
          <br />
          <Row className="justify-content-center">
            <div className="d-flex align-items-center">
              <Col xs={12} className="d-flex justify-content-begin" >
                <input
                  type="text"
                  className="form-control-home"
                  placeholder="E-Mail Address"
                  style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: '1px solid', width: "60%", height: "50px", '::placeholder': { color: 'white' } }}
                  value={inputValue}
                  onChange={handleInputChange}
                />
                <Button href='/auth' variant='outline-light' className='mx-2' > Request a Demo</Button>
              </Col>
            </div>
            <p className='subtitle' style={{ fontSize: '10px', color: 'light gray', marginBottom: '0', marginTop: "5px" }}>You are considered to have accepted data sharing.</p>
          </Row>
        </Col>

        <Col md={6} className='d-flex justify-content-center'>
          <div className="App">
            <img src={images[currentImage]} alt="Dünya" className="world-image" />
          </div>
        </Col>
      </Row>

      <Row className="mt-5 d-flex justify-content-center">
        <Col>
        <Slider {...settings}>
            <div>
              <img src={img}  alt="First slide"  style={{ filter: "brightness(1000%)" }}/>
            </div>
            <div>
              <img src={img2}  alt="Second slide"  style={{ filter: "brightness(1000%)" }}/>
            </div>
            <div>
              <img src={img3}  alt="Third slide"  style={{ filter: "brightness(1000%)" }}/>
            </div>
            <div>
              <img src={img4}  alt="Fourth slide" style={{ filter: "brightness(1000%)" }}/>
            </div>
            <div>
              <img src={img5}  alt="Fifth slide" style={{ filter: "brightness(1000%)" }}/>
            </div>
          </Slider>
        </Col>
      </Row>
    </Container>
  )
}
